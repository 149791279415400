import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/michal/work/bite-api/frontend/dashboard/src/components/layout/dashboard.js";
import { Switcher, CodeSwitcher } from '../../components/switcher';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Authentication`}</h1>
    <p>{`All calls to the API need to be authenticated using Application Keys or Access Tokens.
An Application Key allows you to register application users. User Tokens allow you to perform actions on behalf of a registered user, including logging and viewing meals.
Admin Tokens are used to perform administrative operations such as managing applications and users.`}</p>
    <h2>{`Application Key`}</h2>
    <p>{`The API for creating users is authenticated using an application key. The application key
is safe to include and publish in mobile apps. To view your applications navigate to the
`}<a parentName="p" {...{
        "href": "/"
      }}>{`applications page`}</a>{`.`}</p>
    <p>{`For more on Applications Key see the `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#section/Authentication/Application_Key"
      }}>{`API reference`}</a>{`.`}</p>
    <h2>{`User Token`}</h2>
    <p>{`All user specific calls are authenticated using user tokens. You can view active user tokens `}<a parentName="p" {...{
        "href": "/tokens/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`The token should be provided with each request using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Authorization`}</code>{` HTTP Header. The value of the header must start with
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Bearer`}</code>{` followed by the token. Below is an example of using the key to authenticate an API call.`}</p>
    <CodeSwitcher languages={{
      bash: `curl -X GET 'https://api-beta.bite.ai/meals/' \\
     -H 'Authorization: Bearer [USER TOKEN]'`,
      python: `import biteai\n
user = biteai.User(token='[insert token here]')
meals = user.meals.list()`
    }} mdxType="CodeSwitcher" />
    <p>{`For more information please see the API reference on `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#section/Authentication/User_Bearer_Token"
      }}>{`User Bearer Tokens`}</a>{`.`}</p>
    <h2>{`Admin Token`}</h2>
    <p>{`The API has admin tokens which are used to authenticate endpoints for managing applications or
users. `}<strong parentName="p">{`This token must never be included in client side code`}</strong>{`. You can access the list of your
administrative tokens for your account `}<a parentName="p" {...{
        "href": "/tokens/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`It should be included in your requests via the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Authorization`}</code>{` HTTP Header. The value of the header must start with
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Bearer`}</code>{` followed by your key. Below is an example of using the key to authenticate an API call.`}</p>
    <CodeSwitcher languages={{
      bash: `curl -X GET 'https://api-beta.bite.ai/applications/' \\
     -H 'Authorization: Bearer [Admin Token]'`,
      python: `import biteai\n
admin = biteai.Admin(token='[insert token here]')
apps = admin.applications.list()`
    }} mdxType="CodeSwitcher" />
    <p>{`For more information please see the API reference on `}<a parentName="p" {...{
        "href": "https://api-beta.bite.ai/y7vtjyn4f64c/docs/reference/#section/Authentication/Admin_Bearer_Token"
      }}>{`Admin Bearer Tokens`}</a>{`.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      